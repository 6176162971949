(function () {
    "use strict";

    // @ngInject
    function TopNavControllerCtor(
        $injector, $scope, AppStates, Enums, $translate, $timeout, ModalService, WorkspaceService, FeaturesService,
        EventService, UsersManager, AbTestService, hotkeys, AnalyticsService, OnboardingService, NavigationService,
        CompaniesManager, SetupGuideService, UiPersistenceService, _, $, moment, HomeService, DatadogRUMService, $window, StatsigService,
        PretrialersService, UserService, DeviceService, SearchV2Service, AccountsService, FeatureRestrictionService, FinanceAppService, $rootScope, GlobalBannerService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TopNavController';

        this.AppStates = AppStates;
        this.EventService = EventService;
        this.UsersManager = UsersManager;
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this.$translate = $translate;
        this.OnboardingService = OnboardingService;
        this.$timeout = $timeout;
        this.AnalyticsService = AnalyticsService;
        this.CompaniesManager = CompaniesManager;
        this.ModalService = ModalService;
        this.WorkspaceService = WorkspaceService;
        this.SetupGuideService = SetupGuideService;
        this.UiPersistenceService = UiPersistenceService;
        this.DatadogRUMService = DatadogRUMService;
        this._ = _;
        this.moment = moment;
        this.$ = $;
        this.HomeService = HomeService;
        this.FeaturesService = FeaturesService;
        this.PretrialersService = PretrialersService;
        this.UserService = UserService;
        this.DeviceService = DeviceService;
        this.SearchV2Service = SearchV2Service;
        this.AccountsService = AccountsService;
        this.FeatureRestrictionService = FeatureRestrictionService;
        this.NavigationService = NavigationService;
        this.StatsigService = StatsigService;
        this.isInAppBrowser = this.DeviceService.isInAppBrowser();

        this.shouldShowCleanAtwsUI = this.PretrialersService.shouldShowCleanAtwsUI();
        this.showClientAccessibility = this.UserService.shouldShowClientAccessibilityTopNav(this.user);
        this.showToolsHome = this.shouldShowCleanAtwsUI;
        this.GlobalBannerService = GlobalBannerService;
        this.isShowReactTopNav = null;
        this.isShowReactTopNavRightSide = null;

        this.setStatsigRolloutFlag();
        this.setSideNavEnabled();

        this.StatsigService.getExperimentConfigValue('side_navigation_trailers', 'side_nav_enabled', false)
        .then(function (isSideNavEnabledForTrialers) {
            this.isSideNavEnabledForTrialers = isSideNavEnabledForTrialers;
        }.bind(this));


        this.handleMwebView()

        var onWindowResizeDebounced = _.debounce(this.handleMwebView.bind(this), 500);

        angular.element($window).bind('resize', onWindowResizeDebounced);

        // load user
        this.user = this.UsersManager.getCurrUser();
        this.company = this.CompaniesManager.getCurrCompany();

        // nav bar actions
        this.navBarActions = {
            showSearch: true,
            showRightSetupGuide: false,
            showLeftSetupGuide: false
        };

        FinanceAppService.isFinanceAppEnabled(this.user).then(function (isEligible) {
            this.financeIsEnabled = isEligible;
        }.bind(this));

        this.SetupGuideService.on(this.SetupGuideService.STEP_COMPLETED_EVENT_NAME, function (step) {
            this.setupNavBarActions();
        }.bind(this));

        var description = 'Create Project';
        if (this.user.isVendor()) {
            hotkeys.bindTo($scope).add({
                order: 2,
                combo: 'shift+n',
                description: 'Create Project',
                callback: function addProjectHotkeyCallback(event) {
                    // Stop propagation and prevent default to avoid 'N' appearing in input
                    if (event) {
                        event.preventDefault();
                        event.stopPropagation();
                    }

                    AnalyticsService.track(this, AnalyticsService.analytics_events.hotkey_used, {
                        combo: 'shift+n',
                        action: description
                    });

                    // Direct call without using NavigationService
                    const currUser = this.UsersManager.getCurrUser();
                    this.EventService.createEventCommandWrapper(null, currUser);
                }.bind(this)
            });
        }

        this.updateTopNavForPretrialers();
        UsersManager.on(this.Enums.PubSubTriggers.startedTrial, function startedTrial() {
            this.homeIsEnabled = this.HomeService.shouldShowHome();
            this.updateTopNavForPretrialers();
        }.bind(this));

        CompaniesManager.on(this.Enums.PubSubTriggers.companySubscribed, function companySubscribed() {
            this.updateTopNavForPretrialers();
            this.setupNavBarActions();
        }.bind(this));

        this.setupNavBarActions();

        OnboardingService.on('OnboardingStateRefreshed', function onOnboardingStatusChanged() {
            this.setupNavBarActions();
        }.bind(this));

        SetupGuideService.on('SetupGuideStepsUpdated', function onStepsUpdated() {
            this.setupNavBarActions();
        }.bind(this));

        OnboardingService.on('onboardingAdded', function onOnboardingStarted() {
            this.setupNavBarActions();
        }.bind(this));

        // Home
        this.homeIsEnabled = this.HomeService.shouldShowHome();

        this.loadTopNav();
    }

    Controllers.TopNavController = Class(Controllers.BaseController, {

        constructor: TopNavControllerCtor,

        setStatsigRolloutFlag: function setStatsigRolloutFlag() {
            this.StatsigService.isGateEnabled('rollout_mweb_top_nav').then(function (isRolloutEnabled) {
                this.shouldShowMwebReactTopNav = isRolloutEnabled;
            }.bind(this));
        },

        setSideNavEnabled: function setSideNavEnabled() {
            this.StatsigService.isGateEnabled('side_nav_revamp').then(function (isSideNavEnabled) {
                this.isSideNavEnabled = isSideNavEnabled;
            }.bind(this));
        },

        handleMwebView: function handleMwebView() {
            this.isMobile = this.DeviceService.nxSmallBreakpoint();
            if (this.isMobile) {
                this.setStatsigRolloutFlag();
            }
        },

        loadTopNav: function loadTopNav() {
            if (this.isInAppBrowser || !this.user.isVendor()) {
                // !this.user.isVendor() will be change after i'll add support for user types on new top nav
                this.isShowReactTopNav = false;
                this.isShowReactTopNavRightSide = false;
            } else {
                this.NavigationService.isShowReactTopNav().then(function (isShowReactTopNav) {
                    this.isShowReactTopNav = isShowReactTopNav;
                    this.AnalyticsService.track(
                        this,
                        this.AnalyticsService.analytics_events.top_nav_2023,
                        { is_mobile: false, is_new_top_nav: isShowReactTopNav }
                    );
                }.bind(this))
                    .catch(function () {
                        this.isShowReactTopNav = false;
                        this.DatadogRUMService.addError(
                            'top_nav_2023: error getting variant', { user_id: this.user._id }
                        );
                    }.bind(this));
            }
        },

        onTopNavItemClicked: function onTopNavItemClicked(menuItemKey, subItemKey) {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.top_nav, {
                menu_item: menuItemKey,
                sub_item: subItemKey,
                top_nav_type: this.isShowReactTopNav ? 'variant_extended' : 'control_original'
            });
        },

        onTopNavSetupGuideButtonClick: function onTopNavSetupGuideButtonClick() {
            this.SetupGuideService.onTopNavSetupGuideButtonClick();
            this.onTopNavItemClicked('setup guide');
        },

        updateTopNavForPretrialers: function updateTopNavForPretrialers() {
            var shouldStartTrial = this.user.shouldStartTrial();
            this.upgradeText = '';
            this.communityPreTrialer = false;
            this.showProjects = true;
            this.toolsText = ' MAIN_NAV._TOOLS_';
            this.showToolsHome = this.PretrialersService.shouldShowCleanAtwsUI();
            this.shouldShowCleanAtwsUI = this.showToolsHome;
            this.showClientAccessibility = this.UserService.shouldShowClientAccessibilityTopNav(this.user);

            if (shouldStartTrial) {
                if (this.user.isNetworkPretrialer()) {
                    this.upgradeText = "MAIN_NAV.NETWORK._FREE_";

                    this.updateTopNavForCommunityPretrialer();

                } else if (this.user.isProductPretrialer()) {
                    this.upgradeText = "MAIN_NAV.PRODUCT._FREE_";
                } else {
                    this.upgradeText = "MAIN_NAV.PRODUCT._FREE_";
                }
            }
        },

        updateTopNavForCommunityPretrialer: function updateTopNavForCommunityPretrialer() {
            this.showToolsHome = true;
            this.showProjects = false;
            this.toolsText = ' MAIN_NAV._TOOLS_HOME_';

            //if user has workpaces, show the projects link!
            if (this.user.get_user_workspaces_counts && this.user.get_user_workspaces_counts > 0) {
                this.showProjects = true;
            }
        },

        gotoProjects: function gotoProjects($event) {
            this.onTopNavItemClicked('projects');
            $event.preventDefault();
            if (this.communityPreTrialer === true && this.user.shouldStartTrial()) {
                this.$timeout(function () {
                    this.OnboardingService.openInProductIntakeForm();
                }.bind(this), 1200); // delay
            }
            if (this.shouldShowCleanAtwsUI) {
                this.PretrialersService.gotoLastWorkspace();
            } else {
                var showPipelineOnboarding = this.OnboardingService.shouldShowPipelineOnboarding();
                this.goToState(this.AppStates.root_core_navigation_pipeline, { showFeatureOnboarding: showPipelineOnboarding });
            }
        },

        onLogoDblClick: function onLogoDblClick() {
            this.AbTestService.toggleIsShowAbTestBrowser();
        },

        onHbLogoClick: function onHbLogoClick() {
            this.onTopNavItemClicked('hb_logo');
            if (this.user.isBookkeeperMode()) {
                this.$state.go(this.AppStates.root_core_navigation_bookkeeping_reports_payments);
            }
            else {
                this.$state.go(this.OnboardingService.getOnboardingHomeState());
            }
        },

        setupGuideEnabledOnSmallScreens: function setupGuideEnabledOnSmallScreens() {
            this.isCompanySubscribed = this.user.companyHasSubscription();
            this.isTrialExpired = this.OnboardingService.isTrialExpired();
            this.isPreTrialer = this.user.shouldStartTrial();
            return !this.isCompanySubscribed && !this.isPreTrialer;
        },

        shouldShowSearchBar: function shouldShowSearchBar() {
            var userIsNotPreTrialer = !this.user.shouldStartTrial();
            var setupGuideIsNotActive = !this.SetupGuideService.isSetupGuideActiveForTrialers();
            var isUserSubscribed = this.OnboardingService.isUserSubscribed();

            return (
                userIsNotPreTrialer &&
                (setupGuideIsNotActive || isUserSubscribed)
            );
        },

        setupNavBarActions: function setupNavBarActions() {
            this.isCompanySubscribed = this.OnboardingService.isUserSubscribed();
            this.isTrialExpired = this.OnboardingService.isTrialExpired();
            this.isPreTrialer = this.user.shouldStartTrial();

            this.navBarActions = {
                showSearch: this.shouldShowSearchBar(),
                showRightSetupGuide: this.SetupGuideService.isSetupGuideActiveForSubscribedUsers(),
                showLeftSetupGuide: !this.user.shouldStartTrial() && this.SetupGuideService.isSetupGuideActiveForTrialers()
            };
        },

        showSearchV2: function showSearchV2() {
            this.onTopNavItemClicked('search_button');
            this.SearchV2Service.open();
        },

        getCurrState: function getCurrState() {
            return this.$state.current.name;
        },

        isProjectBtnActive: function isProjectBtnActive() {
            return (this.getCurrState() == 'root.core.navigation.pipeline') || (this.shouldShowCleanAtwsUI && this.getCurrState() == 'root.core.navigation.event_wrapper.event.workspace.feed')
        }
    });
}());
